import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ lang, title, description, image, imageAlt, url, is404 }) => (
    !is404 ? <Helmet 
        htmlAttributes={{ lang }}
        title={ title }
        meta={[
            {
                name: `description`,
                content: description,
            },
            {
                property: `og:title`,
                content: title,
            },
            {
                property: `og:description`,
                content: description,
            },
            {
                property: `og:type`,
                content: `website`,
            },
            {
                property: `og:image`,
                content: image,
            },
            {
                property: `og:url`,
                content: url,
            },
            {
                property: `og:site_name`,
                content: `Grampus G Insurance Agency`
            },
            {
                name: `twitter:card`,
                content: `summary_large_image`,
            },
            {
                name: `twitter:image:alt`,
                content: imageAlt,
            },
        ]}
    /> : 
    <Helmet 
        htmlAttributes={{ lang }} 
        meta={[
            {
                name: `robots`,
                content: `noindex`,
            },
        ]}
    />
);

export default SEO;
