import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faMapMarker, faMobile, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import Button from '../../components/button';

import { PhoneNumberFormatter } from '../../functions/PhoneNumberFormatter';

const Footer = ({ route }) => {
    const data = useStaticQuery(graphql`
        query FooterQuery {
            allContentfulGrampusGFooter {
                nodes {
                contactDetailsHeader
                address
                phoneNumber
                emailAddress
                linksHeader
                homeLinkText
                aboutUsLinkText
                servicesLinkText
                newsletterLinkText
                contactUsLinkText
                copyrightText
                }
            }
        }
    `)

    const { allContentfulGrampusGFooter: { nodes: footer } } = data;

    const [phoneNumber, rawPhoneNumber] = PhoneNumberFormatter(footer[0].phoneNumber);

    const linkIcon = <FontAwesomeIcon className="footer__icon" icon={ faAngleRight } />;
    const mapIcon = <FontAwesomeIcon className="footer__icon color-fixed" icon={ faMapMarker } />;
    const phoneIcon = <FontAwesomeIcon className="footer__icon color-fixed" icon={ faMobile } />;
    const mailIcon = <FontAwesomeIcon className="footer__icon color-fixed" icon={ faEnvelope } />;

    return (
        <footer className="footer">
            <div className="footer__container">
                <div className="footer__flex-container">
                    <div className="footer__contact footer__block">
                        <h3 className="footer__header">{ footer[0].contactDetailsHeader }</h3>
                        <ul className="footer__list">
                            <li className="footer__item"><a className="footer__contact-link" href={ `http://maps.google.com/?q=${ footer[0].address }` } target="_blank" rel="noopener noreferrer">{ mapIcon }{ footer[0].address }</a></li>
                            <li className="footer__item"><a className="footer__contact-link" href={ `tel:${ rawPhoneNumber }` }>{ phoneIcon }{ phoneNumber }</a></li>
                            <li className="footer__item"><a className="footer__contact-link" href={ `mailto:${ footer[0].emailAddress }` }>{ mailIcon }{ footer[0].emailAddress }</a></li>
                        </ul>
                    </div>
                    <nav className="footer__navigation footer__block">
                        <h3 className="footer__header">{ footer[0].linksHeader }</h3>
                        <ul className="footer__list">
                            <li className={ route === 'home' ? 'footer__item current-link' : 'footer__item' }>
                                <Link className="footer__link" to="/">{ linkIcon } { footer[0].homeLinkText }</Link>
                            </li>
                            <li className={ route === 'about' ? 'footer__item current-link' : 'footer__item' }>
                                <Link className="footer__link" to="/about/">{ linkIcon } { footer[0].aboutUsLinkText }</Link>
                            </li>
                            <li className={ route === 'services' ? 'footer__item current-link' : 'footer__item' }>
                                <Link className="footer__link" to="/services/">{ linkIcon } { footer[0].servicesLinkText }</Link>
                            </li>
                            <li className={ route === 'newsletter' ? 'footer__item current-link' : 'footer__item' }>
                                <Link className="footer__link" to="/newsletter/">{ linkIcon } { footer[0].newsletterLinkText }</Link>
                            </li>
                            <li className={ route === 'contact' ? 'footer__item current-link' : 'footer__item' }>
                                <Link className="footer__link" to="/contact/">{ linkIcon } { footer[0].contactUsLinkText }</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="footer__copyright">
                    <span className="footer__copyright-text">
                        Copyright © { new Date().getFullYear() } { footer[0].copyrightText }
                        <br />
                        <small>
                            <Button buttonType="simple-no-underline" to="https://grampusg.com/policy#privacy">Privacy Policy</Button> | 
                            <Button buttonType="simple-no-underline" to="https://grampusg.com/policy#terms">Terms of Service</Button>
                        </small>
                    </span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;