import React from 'react';
import { Link } from 'gatsby';

const Button = ({ buttonType, children, to, onClick }) => (
    !onClick ? 
    <Link className={ `button ${ buttonType }`} to={ to }>
        { children }
    </Link> :
    <span 
        className={ `button ${ buttonType }` } 
        onClick={ onClick }
        onKeyDown={ onClick } 
        role="button"
        tabIndex={0}>
        { children }
    </span>
);

export default Button;
