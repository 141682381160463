import React, { useState } from 'react';
import { Link } from 'gatsby';

import logo from '../../images/logo/logo.png';

const Header = ({ route }) => {
    const [ _isToggleMenu, _setToggleMenu ] = useState(false);
    
    const setToggleMenu = () => {
        _setToggleMenu(prevToggle => !prevToggle);
    }

    return (
        <>
            <header className="header">
                <div className="header__logo-container">
                    <img className="header__logo-image" src={ logo } alt="Logo" />
                </div>
                <nav className="header__navigation">    
                    <ul className="header__list">
                        <li className="header__item">
                            <Link className={ route === 'home' ? 'header__link current-link' : 'header__link' } to="/">Home</Link>
                        </li>
                        <li className="header__item">
                            <Link className={ route === 'about' ? 'header__link current-link' : 'header__link' } to="/about/">About</Link>
                        </li>
                        <li className="header__item">
                            <Link className={ route === 'services' ? 'header__link current-link' : 'header__link' } to="/services/">Services</Link>
                        </li>
                        <li className="header__item">
                            <Link className={ route === 'newsletter' ? 'header__link current-link' : 'header__link' } to="/newsletter/">Newsletter</Link>
                        </li>
                        <li className="header__item">
                            <Link className={ route === 'contact' ? 'header__link current-link' : 'header__link' } to="/contact/">Contact Us</Link>
                        </li>
                    </ul>
                </nav>
                <div 
                    className="header__space header__menu-icon-container" 
                    onClick={ setToggleMenu } 
                    onKeyDown={ setToggleMenu }
                    role="button"
                    tabIndex="0"
                    aria-expanded={ _isToggleMenu }>
                    <span className={ _isToggleMenu ? 'header__menu-icon close-menu' : 'header__menu-icon' }></span>
                </div>
            </header>
            <header className={ _isToggleMenu ? 'header__menu active' : 'header__menu' }>
                <nav className="header__menu-navigation">
                    <ul className="header__menu-list">
                        <li className="header__menu-logo"><img className="header__menu-logo-image" src={ logo } alt="Logo" /></li>
                        <li className={ route === 'home' ? 'header__menu-item current-link' : 'header__menu-item' }>
                            <Link className="header__menu-link" to="/">Home</Link>
                        </li>
                        <li className={ route === 'about' ? 'header__menu-item current-link' : 'header__menu-item' }>
                            <Link className="header__menu-link" to="/about/">About</Link>
                        </li>
                        <li className={ route === 'services' ? 'header__menu-item current-link' : 'header__menu-item' }>
                            <Link className="header__menu-link" to="/services/">Services</Link>
                        </li>
                        <li className={ route === 'newsletter' ? 'header__menu-item current-link' : 'header__menu-item' }>
                            <Link className="header__menu-link" to="/newsletter/">Newsletter</Link>
                        </li>
                        <li className={ route === 'contact' ? 'header__menu-item current-link' : 'header__menu-item' }>
                            <Link className="header__menu-link" to="/contact/">Contact Us</Link>
                        </li>
                    </ul>
                </nav>
            </header>
        </>
    );
}

export default Header;