import React, { useContext } from 'react';
import { StaticQuery, graphql } from "gatsby";

import ConsentBanner from './consent-banner';
import Footer from './footer';
import Header from './header';

import { ConsentContext } from '../../context/ConsentContext';

import ContactBanner from '../../components/contactBanner';

import '../../styles/main.css';
import SEO from '../SEO';

const Layout = ({ children, location, metadata, contactBanner }) => {
    const consentContext = useContext(ConsentContext);

    return (
        <>
            <SEO 
                is404={ metadata.is404 }
                lang={ metadata.lang }
                title={ metadata.title } 
                description={ metadata.description } 
                image={ metadata.image } 
                imageAlt={ metadata.imageAlt } 
                url={ metadata.url } />
            <Header route={ location } />
                <noscript>Please enable javascript for proper functionality</noscript>
                { children }
                <StaticQuery 
                    query={ 
                        graphql`
                            query BannerQuery {
                                contentfulWebsiteContent(id: {eq: "7b904bd3-1d0e-5d9e-940c-558733363812"}) {
                                    reusedComponents {
                                        components {
                                            contactButtonText
                                            tagline
                                            subtagline
                                        }
                                    }
                                }
                            }
                        `}
                    render={ data => (
                        contactBanner ? <ContactBanner 
                        tagline={ data.contentfulWebsiteContent.reusedComponents.components[0].tagline } 
                        subtagline={ data.contentfulWebsiteContent.reusedComponents.components[0].subtagline } 
                        contactUsButtonText={ data.contentfulWebsiteContent.reusedComponents.components[0].contactButtonText } /> : ''
                    )} />
            <Footer route={ location } />
            { !consentContext?._consent && <ConsentBanner setConsent={ consentContext?.giveConsent } /> }
        </>
    );
}

export default Layout;