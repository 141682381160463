import React from 'react';
import { Link } from 'gatsby';

import Button from '../button';

const ConsentBanner = ({ setConsent }) => (
    <div className="consent-banner">
        <div className="consent-banner__text-container">
            <span className="consent-banner__text">
                This site uses cookies for analytic and spam filtering purposes. For more information, please review our &nbsp;
                <Link className="consent-banner__link" to="/policy/">
                    <span className="consent-banner__link">Privacy Policy</span>
                </Link>
            </span>
            <Button className="consent-banner__button" buttonType="secondary" onClick={ setConsent }>I understand</Button>
        </div>
    </div>
)

export default ConsentBanner;
